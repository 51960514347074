// Here you can add other styles

@use "sass:map";

@import url('https://fonts.googleapis.com/css?family=Prompt');

* {
    font-family: 'Prompt';
}

.table {
    margin-bottom: 0px;
}

.pagination {
    padding-left: 1.0rem !important;
    padding-top: 10px;
    color: red;
}

.table thead th {
    border-bottom: 0;
    border-top: 0;
}

.table tr th:first-child {
    padding-left: 1.0rem;
}

.table tr td:first-child {
    padding-left: 1.0rem;
}

.table th,
.table td {
    padding: 0.5rem !important;
}

.v-center {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.c-body-no-padding {
    padding: 0;
    position: relative;
}

.c-body-500 {
    min-height: 600px;
}

.tab-content {
    margin-top: 10px;
}

.nav-tabs {
    border: 0 !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-link:hover {
    border: 0 !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-link {
    border: 0 !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-link.active {}

.badge {
    font-size: 0.7625rem;
    font-weight: normal;
    line-height: 1.5;
}

.required-label:after {
    content: " *";
    color: red;
}

.required {
    border-color: red;
    border-width: 1px;
    opacity: 60% !important;
}

.mfe-2 {
    margin-left: 20px;
}

@media only screen and (max-width: 450px) {

    body {
        font-size: 0.65em !important;
    }

    .badge {
        font-size: 0.55em !important;
    }

    h6 {
        font-size: 1.2em !important;
    }

    h5 {
        font-size: 1.4em !important;
    }

    h4 {
        font-size: 1.5em !important;
    }

    h3 {
        font-size: 1.6em !important;
    }

    h3 {
        font-size: 1.7em !important;
    }

    .btn {
        font-size: 1.2em !important;
    }

    select {
        font-size: 1.4em !important;
    }

    .custom-select {
        font-size: 1.4em !important;
    }

    .form-control {
        font-size: 1.2em !important;
    }

    .card-header {
        padding: 0.45rem 1rem !important;
    }

    .card-body {
        padding: 1rem !important;
    }

    img {
        width: 4rem !important;
        height: 4rem !important;
    }

    .washing-machine {
        font-size: 0.7rem !important;
    }

    .dryer-machine {
        font-size: 0.7rem !important;
    }

}

.bg-codeclean {
    background-color: #edc144;
}

.c-app.c-dark-theme {
    --codeclean: #edc144;
}

.product-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gray-scale {
    filter: grayscale(1) !important;
}

.branch-div:hover {
    background-color: #edc144;
    color: white;
}

.c-sidebar-nav-dropdown.c-show>.c-sidebar-nav-dropdown-items {
    max-height: 3000px !important;
}